import {Injectable} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {AssessmentRequestStatus} from '../core/enums/assessment-request-status.enum';

@Injectable()
export class AssessmentRequestPermissionsService {
  constructor(private authService: AuthService) {
  }

  public allowEdit(status: AssessmentRequestStatus): boolean {
    if( (status === AssessmentRequestStatus.Complete || status === AssessmentRequestStatus.Deleted) && this.authService.isRoleAdmin)
      return true;

    if (status === AssessmentRequestStatus.Complete || status === AssessmentRequestStatus.Deleted ||
      status === AssessmentRequestStatus.Unknown) {
      return false;
    }

    // only admins can edit when status is "in progress"
    return !((status === AssessmentRequestStatus.InProcess || status === AssessmentRequestStatus.InProcessTestingComplete || status === AssessmentRequestStatus.InProcessReportsUploaded) && this.authService.isRoleClient);
  }

  public allowDelete(status: AssessmentRequestStatus): boolean {
    if (status === AssessmentRequestStatus.Complete || status === AssessmentRequestStatus.Unknown ||
      status === AssessmentRequestStatus.Deleted) {
      return false;
    }

    if (status === AssessmentRequestStatus.NotSubmitted)
      return true;

    return this.authService.isRoleAdmin;
  }

  public allowAttachmentPurge(): boolean {
    return this.authService.isRoleAdmin;
  }

  public allowUploadReports(status: AssessmentRequestStatus): boolean {
    return (status === AssessmentRequestStatus.InProcess || status === AssessmentRequestStatus.InProcessTestingComplete || status === AssessmentRequestStatus.InProcessReportsUploaded) && this.authService.isRoleAdmin;
  }

  public allowDownloadReports(status: AssessmentRequestStatus, reportsUploaded: boolean): boolean {
    if (this.authService.userLevel === 1 && !this.authService.isRoleAdmin) {
      return false;
    }
    return status === AssessmentRequestStatus.Complete || ((status === AssessmentRequestStatus.InProcess || status === AssessmentRequestStatus.InProcessTestingComplete || status === AssessmentRequestStatus.InProcessReportsUploaded) && reportsUploaded);
  }

  public allowDownloadResume(): boolean {
    return this.authService.isRoleAdmin;
  }

  public allowDownloadJobDescription(): boolean {
    return this.authService.isRoleAdmin;
  }

  public allowAdditionalServices(status: AssessmentRequestStatus, parentAssessmentRequestId: number): boolean {
    return (status !== AssessmentRequestStatus.Unknown && status !== AssessmentRequestStatus.Deleted && !parentAssessmentRequestId);
  }

  public allowTestingSchedule(status: AssessmentRequestStatus): boolean {
    return (status === AssessmentRequestStatus.Submitted || status === AssessmentRequestStatus.InProcess || AssessmentRequestStatus.InProcessTestingComplete) && this.authService.isRoleAdmin;
  }

  public allowAssignConsultant(status: AssessmentRequestStatus): boolean {
    return status != AssessmentRequestStatus.Complete && this.authService.isRoleAdmin;
  }

  public isAdmin(): boolean {
    return this.authService.isRoleAdmin;
  }
  /// Rules:
  /// Client User can download if not in list.
  /// Only if not deleted.
  /// Admin can download anywhere.
  public allowDownloadAssessmentRequest(status: AssessmentRequestStatus, inList: boolean = false): boolean {
    if (this.authService.isRoleClient) {
      if (inList) {
        return false;
      }
    }
    return status !== AssessmentRequestStatus.Deleted;
  }
}
